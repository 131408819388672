import {NgModule} from '@angular/core';
import {NoPreloading, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {Auth0BusinessGuard} from './guards/auth0business.guard';
import {Auth0CallbackComponent} from './public/auth0-callback/auth0-callback.component';
import {BusinessResolverService} from './resolvers/businessresolver.service';
import {Auth0GuestWelcomeGuard} from './guards/auth0guestwelcome.guard';
import {Auth0GuestPurchaseGuard} from './guards/auth0guestpurchase.guard';
import {Auth0adminGuard} from './guards/auth0admin.guard';
import {Auth0SignupGuard} from './guards/auth0signup.guard';
import {Auth0GuestWelcomeSignupGuard} from './guards/auth0guestwelcomesignup.guard';
import {Auth0PageModule} from './public/auth0/auth0.module';
import {Auth0GuestWelcomeSuccessGuard} from './guards/auth0guestwelcomesuccess.guard';
import {UnavailablePageModule} from './guest/welcome/unavailable/unavailable.module';
import {Auth0activateGuard} from './guards/auth0activate.guard';
import {Auth0SetupGuard} from './guards/auth0setup.guard';
import {Auth0SetupSignupGuard} from './guards/auth0setupsignup.guard';
import {Auth0claimGuard} from './guards/auth0claim.guard';
import {Auth0AffiliatePayGuard} from './guards/auth0affiliatepay.guard';
import {PayCompletePageModule} from './affiliate/pay-complete/pay-complete.module';
import {Auth0DesktopGuard} from './guards/auth0desktop.guard';
import {Auth0DesktopSignupGuard} from './guards/auth0desktopsignup.guard';
import {Auth0AffiliateAuthorizeGuard} from './guards/auth0affiliateauthorize.guard';
import {Auth0GuestWelcomeVerifyGuard} from './guards/auth0guestwelcomeverify.guard';
import {Auth0DesktopAcceptGuard} from './guards/auth0desktopaccept.guard';


const routes: Routes = [
    {
        path: '',
        loadChildren: './public/start/start.module#StartPageModule',
        pathMatch: 'full'
    },

    {path: 'authorization-callback', loadChildren: './public/auth/auth.module#AuthPageModule'},
    {
        path: 'members',
        canActivate: [Auth0BusinessGuard],
        loadChildren: './members/member-routing.module#MemberRoutingModule',
        /*resolve: {
            isLoaded: BusinessResolverService
        }*/

    },

    {path: 'public/qr-creator',
        canActivate: [Auth0BusinessGuard], loadChildren: './public/qr-creator/qr-creator.module#QrCreatorPageModule'},
    {path: 'public/salesforce/activation', loadChildren: './public/salesforce/activation/activation.module#ActivationPageModule'},
    {path: 'public/salesforce/plaid', loadChildren: './public/salesforce/plaid/plaid.module#PlaidPageModule'},
    {path: 'public/salesforce/qr', loadChildren: './public/salesforce/qr/qr.module#QrPageModule'},

    {path: 'auth0', loadChildren: './public/auth0/auth0.module#Auth0PageModule'},
    {path: 'auth0-callback', component: Auth0CallbackComponent},
    {path: 'auth0-two-factor', loadChildren: './public/auth0-two-factor/auth0-two-factor.module#Auth0TwoFactorPageModule'},
    {path: 'aa', loadChildren: './debit-add-start/debit-add-start.module#DebitAddStartPageModule'},
    {path: 'login', loadChildren: './public/start/start.module#StartPageModule'},

    // guest pages

    {
        path: 'activate/:bid',
        loadChildren: './activate/home/home.module#HomePageModule'
    },
    {
        canActivate: [Auth0activateGuard],
        path: 'activate/:bid/code',
        loadChildren: './activate/code/code.module#CodePageModule'
    },


    {
        canActivate: [Auth0activateGuard],
        path: 'activate/:bid/success',
        loadChildren: './activate/success/success.module#SuccessPageModule'
    },


    {
        path: 'affiliate/pay',
        canActivate: [Auth0AffiliatePayGuard],
        loadChildren: './affiliate/pay/pay.module#PayPageModule'
    },

    {
        path: 'affiliate/pay-complete',
        canActivate: [Auth0AffiliatePayGuard],
        loadChildren: './affiliate/pay-complete/pay-complete.module#PayCompletePageModule'
    },


    //I am sorry
    //Redirect is home
    //Home is redirect
    //Why?  Because qr codes now are dynamic


    {
        path: 'guest/business/:bid/guests/:gid/welcome',
        loadChildren: './guest/welcome/redirect/redirect.module#RedirectPageModule'
    },
    {
        path: 'guest/business/:bid/guests/:gid/redirect',
        loadChildren: './guest/welcome/home/home.module#HomePageModule'
    },
    {
        path: 'guest/business/:bid/guests/:gid/welcome/plaid',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/plaid/plaid.module#PlaidPageModule'
    },
    {
        path: 'guest/business/guests/welcome/plaid',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/plaid/plaid.module#PlaidPageModule'
    },

    {
        path: 'guest/business/guests/welcome/add',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/add/add.module#AddPageModule'
    },
    {
        path: 'guest/business/guests/welcome/unavailable',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/unavailable/unavailable.module#UnavailablePageModule'
    },
    {
        path: 'guest/business/guests/welcome/pay',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/pay/pay.module#PayPageModule'
    },
    {
        path: 'guest/business/guests/welcome/transactions',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/transactions/transactions.module#TransactionsPageModule'
    },
    {
        path: 'guest/business/guests/welcome/pay-success',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/pay-success/pay-success.module#PaySuccessPageModule'
    },

    {
        path: 'guest/business/:bid/guests/:gid/welcome/chooser',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/chooser/chooser.module#ChooserPageModule'
    },

    {
        path: 'guest/business/:bid/guests/:gid/welcome/success',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/success/success.module#SuccessPageModule'
    },


    {
        path: 'guest/business/guests/welcome/confirm',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/confirm/confirm.module#ConfirmPageModule'
    },
    {
        path: 'guest/business/guests/welcome/success',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/success/success.module#SuccessPageModule'
    },


    {
        path: 'person/:pid/verify/microdeposit',
        loadChildren: () => import('./person/verify/microdeposit/microdeposit.module').then( m => m.MicrodepositPageModule)
    },

    {
        path: 'guest/business/guests/welcome/funding',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: () => import('./guest/welcome/funding/funding.module').then( m => m.FundingPageModule)
    },

    {
        path: 'guest/business/guests/welcome/verified',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: () => import('./guest/welcome/verified/verified.module').then( m => m.VerifiedPageModule)
    },

    {
        path: 'guest/business/guests/welcome/passcode',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: () => import('./guest/welcome/passcode/passcode.module').then( m => m.PasscodePageModule)
    },
    {
        path: 'guest/business/guests/welcome/authorize',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: () => import('./guest/welcome/authorize/authorize.module').then( m => m.AuthorizePageModule)
    },
    {
        path: 'guest/business/guests/welcome/name',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: () => import('./guest/welcome/name/name.module').then( m => m.NamePageModule)
    },
    {
        path: 'guest/business/:bid/guests/:gid/welcome/verify',
        canActivate: [Auth0GuestWelcomeVerifyGuard],
        loadChildren: () => import('./guest/welcome/verify/verify.module').then( m => m.VerifyPageModule)
    },
    {
        path: 'guest/business/guests/welcome/unverified',
        canActivate: [Auth0GuestWelcomeVerifyGuard],
        loadChildren: () => import('./guest/welcome/unverified/unverified.module').then( m => m.UnverifiedPageModule)
    },


    {
        path: 'guest/business/:bid/guests/:gid/welcome/two-factor',
        canActivate: [Auth0GuestWelcomeGuard],
        loadChildren: './guest/welcome/two-factor/two-factor.module#TwoFactorPageModule'
    },


    {
        path: 'guest/business/:bid/guests/:gid/welcome/signup',
        canActivate: [Auth0GuestWelcomeSignupGuard],
        loadChildren: './guest/welcome/two-factor/two-factor.module#TwoFactorPageModule'
    },


    {
        path: 'guest/business/:bid/guests/:gid/purchases/:pid',
        loadChildren: './guest/purchase/home/home.module#HomePageModule'
    },
    {
        path: 'guest/business/:bid/guests/:gid/purchases/:pid/two-factor',
        canActivate: [Auth0GuestPurchaseGuard],
        loadChildren: './guest/purchase/two-factor/two-factor.module#TwoFactorPageModule'
    },
    {
        path: 'guest/business/guests/purchases/receipt',
        canActivate: [Auth0GuestPurchaseGuard],
        loadChildren: './guest/purchase/receipt/receipt.module#ReceiptPageModule'
    },
    {
        path: 'guest/business/guests/purchases/tip-success',
        canActivate: [Auth0GuestPurchaseGuard],
        loadChildren: './guest/purchase/tip-success/tip-success.module#TipSuccessPageModule'
    },
    {
        path: 'guest/business/guests/purchases/receipt-cancel-success',
        canActivate: [Auth0GuestPurchaseGuard],
        loadChildren: './guest/purchase/receipt-cancel-success/receipt-cancel-success.module#ReceiptCancelSuccessPageModule'
    },

    {path: 'guest/error', loadChildren: './guest/error/error.module#ErrorPageModule'},
    {path: 'l/:hash', loadChildren: './public/l/l.module#LPageModule'},
    {path: 'qr/:uuid', loadChildren: './public/qr/qr.module#QrPageModule'},

    // {path: 'setup',
    //     loadChildren: './public/desktop/setup-login/setup-login.module#SetupLoginPageModule'},
    {path: 'claim',
        loadChildren: './claim/home/home.module#HomePageModule'},
    {path: 'claim-complete/:uuid',  canActivate: [Auth0claimGuard],
        loadChildren: './claim/complete/complete.module#CompletePageModule'},

    {path: 'public/desktop/setup',
         loadChildren: './public/desktop/setup-login/setup-login.module#SetupLoginPageModule'},
    {path: 'public/desktop/setup-page',         canActivate: [Auth0SetupGuard],
        loadChildren: './public/desktop/setup/setup.module#SetupPageModule'},
    {path: 'public/desktop/setup-signup',         canActivate: [Auth0SetupSignupGuard],
        loadChildren: './public/desktop/setup/setup.module#SetupPageModule'},


    {
        path: 'setup',
        canActivate: [Auth0DesktopSignupGuard],
        loadChildren: () => import('./desktop/home/home.module').then(m => m.HomePageModule)
    },

    {
        path: 'desktop/reports',
        canActivate: [Auth0DesktopGuard],
        loadChildren: () => import('./desktop/reports/reports.module').then( m => m.ReportPageModule)
    },
    {
        path: 'desktop/settings',
        canActivate: [Auth0DesktopGuard],
        loadChildren: () => import('./desktop/settings/settings.module').then( m => m.SettingsPageModule)
    },


    {path: 'public/eula', loadChildren: './public/eula/eula.module#EulaPageModule'},
    {path: 'public/eula-subscription', loadChildren: './public/eulasubscription/eulasubscription.module#EulaSubscriptionPageModule'},
    {path: 'public/privacy', loadChildren: './public/privacy/privacy.module#PrivacyPageModule'},
    {path: 'public/contact', loadChildren: './public/contact/contact.module#ContactPageModule'},
    {
        path: 'logout',
        loadChildren: './logout/logout.module#LogoutPageModule'
    },
    {
        path: 'logout-success', loadChildren: './logout-success/logout-success.module#LogoutSuccessPageModule'
    },
    {
        path: 'guest/business/guests/logout', loadChildren: './guest/utils/logout/logout.module#LogoutPageModule'
    },
    {path: 'import', loadChildren: './private/modals/import/import.module#ImportPageModule'},
    {path: 'wechat', loadChildren: './public/wechat/wechat.module#WechatPageModule'},
    {
        path: 'signup',
        canActivate: [Auth0SignupGuard],
        loadChildren: './guests-init-two-factor/guests-init-two-factor.module#GuestsInitTwoFactorPageModule'
    },

    {
        path: 'members/menu/guests-init-two-factor',
        canActivate: [Auth0BusinessGuard],
        loadChildren: './guests-init-two-factor/guests-init-two-factor.module#GuestsInitTwoFactorPageModule'
    },
    {
        path: 'members/menu/guests-init-next',
        canActivate: [Auth0BusinessGuard],
        loadChildren: './guests-init-next/guests-init-next.module#GuestsInitNextPageModule'
    },


    {
        path: 'guests-init-two-factor',
        canActivate: [Auth0BusinessGuard],
        loadChildren: './guests-init-two-factor/guests-init-two-factor.module#GuestsInitTwoFactorPageModule'
    },
    {
        path: 'guests-init-next',
        canActivate: [Auth0BusinessGuard],
        loadChildren: './guests-init-next/guests-init-next.module#GuestsInitNextPageModule'
    },
  {
    path: 'demo',
    loadChildren: () => import('./public/demo/demo.module').then( m => m.DemoPageModule)
  },
  {
    path: 'desktop/home',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'desktop/setup/setup',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/setup/setup/setup.module').then( m => m.SetupPageModule)
  },
  {
    path: 'desktop/setup/pending',
      canActivate: [Auth0DesktopGuard],
    loadChildren: () => import('./desktop/setup/pending/pending.module').then( m => m.PendingPageModule)
  },
    {
        path: 'desktop/setup/document',
        canActivate: [Auth0DesktopGuard],
        loadChildren: () => import('./desktop/setup/document/document.module').then( m => m.DocumentPageModule)
    },

    {
        path: 'desktop/public/accept',
        canActivate: [Auth0DesktopAcceptGuard],

        loadChildren: () => import('./desktop/public/accept/accept.module').then( m => m.AcceptPageModule)
    },


    {
    path: 'affiliate/authorize',
    loadChildren: () => import('./affiliate/authorize/authorize.module').then( m => m.AuthorizePageModule)
  },
  {
    path: 'affiliate/authorize-add',
      canActivate: [Auth0AffiliateAuthorizeGuard],
    loadChildren: () => import('./affiliate/authorize-add/authorize-add.module').then( m => m.AuthorizeAddPageModule)
  },
  {
    path: 'affiliate/authorize-decline',
      canActivate: [Auth0AffiliateAuthorizeGuard],
    loadChildren: () => import('./affiliate/authorize-decline/authorize-decline.module').then( m => m.AuthorizeDeclinePageModule)
  },
  {
    path: 'guest/welcome/checktext',
    loadChildren: () => import('./guest/welcome/checktext/checktext.module').then( m => m.ChecktextPageModule)
  },
  {
    path: 'down',
    loadChildren: () => import('./public/down/down.module').then( m => m.DownPageModule)
  },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading, enableTracing: false, onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
